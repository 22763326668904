import axios from "axios";

const BASE_URL = 'https://avd-backend.veon-tech.ru/api/'

const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

export default api;
