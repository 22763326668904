import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import SearchForm from "../main/search-form";
import AccordionAnalogueCart from "./accordion-analogue-cart";
import {addItem, removeItem} from "../../redux/slices/cartSlice";
import {useDispatch} from "react-redux";

const ProductCart = ({ location, url, navigate, catalog }) => {
    const dispatch = useDispatch();
    const [chosenProduct, setChosenProduct] = useState({});
    const [analogueProduct, setAnalogueProduct] = useState([]);
    const [limit, setLimit] = useState(6);
    const [quantity, setQuantity] = useState(1);

    useEffect(() => {
        loadProductCart();
        setLimit(6);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [location]);

    const loadProductCart = async () => {
        const loc = location.pathname.split('/');
        const key = loc.pop();
        await fetch(`${url}/api/products/${key}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => response.json())
            .then(result => {
                if (result) {
                    setChosenProduct(result.product);
                    setAnalogueProduct(result.analogs);
                    setQuantity(1); // сбрасываем количество при загрузке нового продукта
                }
            });
    };

    const incrementQuantity = () => {
        setQuantity(prevQuantity => Math.min(prevQuantity + 1, 20)); // максимальное значение - 20
    };

    const decrementQuantity = () => {
        setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1)); // минимальное значение - 1
    };

    const addToCart = () => {
        dispatch(addItem({
            ...chosenProduct,
            quantity: quantity,
        }));
        alert('Товар добавлен в корзину');
    };

    return (
        <main className='product-cart__wrapper'>
            <SearchForm catalog={catalog} />
            <section className='product-cart'>
                <div className='photo__wrapper'>
                    <img src='/images/default-icon-product.svg' alt='product-pic' />
                </div>
                <div className='product-cart__description'>
                    <h2>{chosenProduct.name}</h2>
                    <p>Aртикул группы аналогов: {chosenProduct?.group_of_analogs?.analogs_group_article}</p>
                    <p>Артикул: <span>{chosenProduct.article}</span></p>
                    <p>Производитель: <span>{chosenProduct.manufacturer}</span></p>
                    <p>Страна: <span>{chosenProduct.country}</span></p>
                    <p>Описание: <span>{chosenProduct.description}</span></p>
                    <div className='product-cart__footer'>
                        <p>{chosenProduct.price} руб</p>
                        <div>
                            <button onClick={decrementQuantity}>-</button>
                            <p>{quantity}</p>
                            <button onClick={incrementQuantity}>+</button>
                        </div>
                        <button onClick={addToCart}>В корзину</button>
                    </div>
                </div>
            </section>
            {analogueProduct.length > 0 &&
                <section className='analogue-product__flex'>
                    <h2>Аналоги товара</h2>
                    <AccordionAnalogueCart
                        current={chosenProduct.article}
                        navigate={navigate}
                        location={location.pathname}
                        analogue={analogueProduct}
                        limit={limit}
                    />
                    {limit < analogueProduct.length &&
                        <button onClick={() => setLimit(limit + 5)}>Показать ещё товары</button>
                    }
                </section>
            }
        </main >
    );
};

export default ProductCart;
