import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSignupUserMutation } from '../../redux/rtk/api-rtk';
import InputMask from 'react-input-mask';

const RegistrationForm = ({ navigate }) => {
    const [statePassword, changeStatePassword] = useState(false);
    const [showAgreementError, setShowAgreementError] = useState(false); // Состояние для отображения ошибки

    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        reset,
    } = useForm({ mode: 'onBlur' });

    const [signup, { isLoading, error }] = useSignupUserMutation();

    const onSubmit = async (data) => {
        // Проверка наличия согласия с условиями обслуживания
        if (!data.apply_agreement) {
            setShowAgreementError(true); // Показываем уведомление
            return; // Отменяем отправку формы
        }

        // Преобразование номера телефона в нужный формат
        const formattedPhone = data.phone.replace(/\D/g, '');
        const formData = {
            name: data.name,
            lastname: data.lastname,
            patronymic: data.patronymic,
            phone: formattedPhone,
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
        };

        try {
            const result = await signup(formData).unwrap();
            console.log('Успешная регистрация:', result);
            reset();
            navigate('/login');
        } catch (err) {
            console.error('Ошибка регистрации:', err);
        }
    };

    return (
        <form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
            {/* Поля формы */}
            <label>
                Имя
                <input
                    type="text"
                    placeholder="Введите имя"
                    className={errors.name ? 'error' : ''}
                    {...register('name', {
                        required: 'Это поле обязательно к заполнению.',
                    })}
                />
            </label>
            <div className="error-message">
                {errors?.name && <p>{errors?.name.message || 'Error!'}</p>}
            </div>

            <label>
                Фамилия
                <input
                    type="text"
                    placeholder="Введите фамилию"
                    className={errors.lastname ? 'error' : ''}
                    {...register('lastname', {
                        required: 'Это поле обязательно к заполнению.',
                    })}
                />
            </label>
            <div className="error-message">
                {errors?.lastname && <p>{errors?.lastname.message || 'Error!'}</p>}
            </div>

            <label>
                Отчество (необязательно)
                <input
                    type="text"
                    placeholder="Введите отчество (если есть)"
                    {...register('patronymic')}
                />
            </label>

            <label>
                Телефон
                <InputMask
                    mask="+7 (999) 999-99-99"
                    maskChar="_"
                    placeholder="+7 (___) ___-__-__"
                    className={errors.phone ? 'error' : ''}
                    {...register('phone', {
                        required: 'Это поле обязательно к заполнению.',
                        pattern: {
                            value: /^(\+7)?[\s\-]?\(?\d{3}\)?[\s\-]?\d{3}[\s\-]?\d{2}[\s\-]?\d{2}$/,
                            message: 'Некорректный номер телефона.',
                        },
                    })}
                />
            </label>
            <div className="error-message">
                {error?.data?.errors?.phone && (
                    <p>{error?.data.errors.phone[0] || 'Error!'}</p>
                )}
            </div>

            <label>
                Электронная почта
                <input
                    type="email"
                    placeholder="Введите электронную почту"
                    className={errors.email ? 'error' : ''}
                    {...register('email', {
                        required: 'Это поле обязательно к заполнению.',
                        pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/,
                            message: 'E-mail введён некорректно.',
                        },
                    })}
                />
            </label>
            <div className="error-message">
                {error?.data?.errors?.email && (
                    <p>{error?.data.errors.email[0] || 'Error!'}</p>
                )}
            </div>

            <label className="password">
                Пароль
                <input
                    type={statePassword ? 'text' : 'password'}
                    className={`${statePassword ? 'active' : ''} ${
                        errors.password ? 'error' : ''
                    }`}
                    placeholder="Введите пароль"
                    {...register('password', {
                        required: 'Это поле обязательно к заполнению.',
                        minLength: {
                            value: 6,
                            message: 'Пароль должен содержать от 6 до 16 символов.',
                        },
                        maxLength: {
                            value: 16,
                            message: 'Пароль должен содержать от 6 до 16 символов.',
                        },
                        pattern: {
                            value: /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/,
                            message: 'Недостаточно сложный пароль.',
                        },
                    })}
                />
                <p
                    className={statePassword ? 'active' : ''}
                    onClick={() => changeStatePassword(!statePassword)}
                ></p>
            </label>
            <div className="error-message">
                {errors?.password && <p>{errors?.password.message || 'Error!'}</p>}
            </div>

            <label className="password">
                Повторите пароль
                <input
                    type={statePassword ? 'text' : 'password'}
                    className={`${statePassword ? 'active' : ''} ${
                        errors.password_confirmation ? 'error' : ''
                    }`}
                    placeholder="Повторите пароль"
                    {...register('password_confirmation', {
                        required: 'Это поле обязательно к заполнению.',
                        validate: (value) => {
                            const { password } = getValues();
                            return value === password || 'Пароли не совпадают.';
                        },
                    })}
                />
                <p
                    className={statePassword ? 'active' : ''}
                    onClick={() => changeStatePassword(!statePassword)}
                ></p>
            </label>
            <div className="error-message">
                {errors?.password_confirmation && (
                    <p>{errors?.password_confirmation.message || 'Error!'}</p>
                )}
            </div>

            <div className="apply-agreement">
                <label>
                    <input
                        type="checkbox"
                        className={errors?.apply_agreement ? 'error' : ''}
                        {...register('apply_agreement')}
                    />
                    Условия обслуживания
                </label>
            </div>
            {showAgreementError && (
                <p className="agreement-error">
                    Пожалуйста, примите условия обслуживания перед регистрацией.
                </p>
            )}
            <input type="submit" value="Регистрация" />
            {isLoading && <p>Регистрация...</p>}
            {error && (
                <p className="">
                    Ошибка: {error.data?.message || 'Не удалось зарегистрироваться'}
                </p>
            )}
        </form>
    );
};

export default RegistrationForm;
