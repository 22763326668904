// import {useEffect, useState} from "react";
// import SearchForm from "../main/search-form";
// import {useDispatch, useSelector} from "react-redux";
// import Lottie from "lottie-react";
// import spinner from '../../assets/spinner.json';
// import {categoriesStore, handleLoading, productsStore} from "../../redux/slices/catalogSlice";
// import {Link, useLocation} from "react-router-dom";
// import AccordionAnalogue from "./accordion-analogue";
// import {logDOM} from "@testing-library/react";
//
//
// const ModelCatalog = ({url}) => {
//     const dispatch = useDispatch();
//     const location = useLocation();
//     const {categories, products, chosenBrand, chosenModel, isLoading} = useSelector(store => store.catalogSlice);
//     const [activeKey, setActiveKey] = useState(null)
//     const [subCategory, setSubCategory] = useState(null)
//     const [previousKey, setPreviousKey] = useState(null);
//     const activeCategory = activeKey && categories.filter(el => el.key === activeKey)[0].child_categories;
//     const path = location.pathname.split('/');
//     const model = path.pop();
//     const brand = path.pop();
//
//     console.log(subCategory)
//
//
//     const getCategories = async () => {
//         dispatch(handleLoading({isLoading: true}));
//         await fetch(`${url}/api/products/categories?brand=${brand}&model=${model}`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/json'
//             }
//         })
//             .then(response => response.json())
//             .then(result => {
//                 dispatch(categoriesStore(result.categories));
//                 dispatch(handleLoading({isLoading: false}));
//                 setActiveKey(result.categories[0].key);
//             })
//     }
//
//     const getProducts = async (category) => {
//         dispatch(handleLoading({isLoading: true}));
//         await fetch(`${url}/api/products?brand=${brand}&model=${model}&category=${category}`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/json'
//             }
//         })
//             .then(response => response.json())
//             .then(result => {
//                 dispatch(productsStore(result.products));
//                 dispatch(handleLoading({isLoading: false}));
//             })
//     }
//
//     const onClickOpenHandler = (key) => {
//         if (key !== previousKey) {
//             dispatch(productsStore([]));
//             getProducts(key);
//         } else if (key === previousKey) {
//             dispatch(productsStore([]));
//         } else {
//             getProducts(key);
//         }
//         setPreviousKey(key);
//     };
//
//     useEffect(() => {
//         getCategories()
//     }, []);
//
//     return (
//         <main>
//             <SearchForm/>
//             {isLoading && <Lottie className='animation' animationData={spinner} loop={true}/>}
//             <div className='model-catalog'>
//                 <h1>{decodeURIComponent(brand) + ', ' + decodeURIComponent(model)}</h1>
//                 <div className='model-catalog__wrapper'>
//                     <aside>
//                         <ul>
//                             {categories.map((item, index) => (
//                                 <li
//                                     className={`${item.key === activeKey ? 'open' : ''}`}
//                                     key={index}
//                                     onClick={() => setActiveKey(item.key)}
//                                 >
//                                     {item.name}
//                                     <button
//                                         className={`model-catalog_button`}
//                                         // className={`model-catalog_button ${item.key === asideOpenCategory ? 'active' : ''}`}
//                                         data-value={item.key}
//                                     ></button>
//                                 </li>
//                             ))}
//                         </ul>
//                     </aside>
//                     {/*<div>*/}
//                     {/*    {activeCategory?.map((el,index) => {*/}
//                     {/*        return(*/}
//                     {/*            <>*/}
//                     {/*                <div key={index} onClick={() =>  onClickOpenHandler(el.key)} >*/}
//                     {/*                    {el.name}*/}
//                     {/*                    {*/}
//                     {/*                        products && products.map((prod, index) => {*/}
//                     {/*                            return (*/}
//                     {/*                                <div key={index}>{prod.name}</div>*/}
//                     {/*                            )*/}
//                     {/*                        })*/}
//                     {/*                    }*/}
//
//
//                     {/*                </div>*/}
//                     {/*                {el.products_count === '0' && el.child_categories.map((subEl, index) => {*/}
//                     {/*                    return(*/}
//                     {/*                        <>*/}
//                     {/*                            <div key={index}>*/}
//                     {/*                                <AccordionAnalogue location={location.pathname}*/}
//                     {/*                                                                analogue={subEl}*/}
//                     {/*                                                                i={index}*/}
//                     {/*                            />*/}
//                     {/*                            </div>*/}
//                     {/*                            {subEl.products_count === '0' &&*/}
//                     {/*                                subEl.child_categories.map((subEl, index) => {*/}
//                     {/*                                    return(*/}
//                     {/*                                        <div key={index}>*/}
//                     {/*                                            <AccordionAnalogue location={location.pathname}*/}
//                     {/*                                                               analogue={subEl}*/}
//                     {/*                                                               i={index}*/}
//                     {/*                                            />*/}
//                     {/*                                        </div>*/}
//
//                     {/*                                    )*/}
//                     {/*                                })*/}
//                     {/*                            }*/}
//                     {/*                        </>*/}
//                     {/*                    )*/}
//                     {/*                })*/}
//                     {/*                }*/}
//                     {/*            </>*/}
//                     {/*        )*/}
//                     {/*    })}*/}
//                     {/*</div>*/}
//                     <div>
//                         {activeCategory && activeCategory.map((product, index) => {
//                            // console.log(product    )
//                             return (
//                                 <div className='product-group__wrapper' key={index}>
//                                     <div className={`product-group`}>
//                                         <div>
//                                             <h2>{product.name}</h2>
//                                         </div>
//                                         {product.products_count === '0' ?
//                                             <button
//                                                 // className={product.key === mainOpenCategory ? 'active' : ''}
//                                                 data-value={product.key}
//                                                 onClick={() => {
//                                                     console.log('доп')
//                                                     setSubCategory(product)
//                                                 }}
//                                             >
//                                                 {product.key === products[0]?.category.key ? 'Скрыть все доп товары' : 'Показать все доп товары'}
//                                             </button>
//                                             :
//                                             <button
//                                                 // className={product.key === mainOpenCategory ? 'active' : ''}
//                                                 data-value={product.key}
//                                                 onClick={() => {
//                                                     onClickOpenHandler(product.key)
//                                                 }}
//                                             >
//                                                 {product.key === products[0]?.category.key ? 'Скрыть все товары' : 'Показать все товары'}
//                                             </button>
//                                         }
//                                     </div>
//                                     <div
//                                         //className={`analogue-products ${product.key === mainOpenCategory ? 'open' : 'hidden'}`}
//                                     >
//                                         {product.key === products[0]?.category.key &&
//                                             <div
//                                                 className={`analogue-products ${product.key === products[0]?.category.key ? 'open' : 'hidden'}`}
//                                             >
//                                                 {products.map((item, i) => {
//                                                     console.log(item)
//                                                     return (
//                                                         <div key={i}>
//                                                             <AccordionAnalogue location={location.pathname}
//                                                                                analogue={item}
//                                                                                i={i}
//                                                             />
//                                                         </div>
//                                                     )
//                                                 })}
//                                             </div>
//                                         }
//
//                                     </div>
//                                 </div>
//                             )
//                         })}
//                         {subCategory && subCategory.child_categories.map((el, index) => {
//                             console.log(el)
//                             return(
//                                 <div>{el.name}</div>
//                             )
//                         })
//                         }
//                     </div>
//                 </div>
//             </div>
//         </main>
//     )
// }
//
// export default ModelCatalog;


import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {categoriesStore, handleLoading} from "../../redux/slices/catalogSlice";
import "./ModelCatalog.scss";
import AccordionAnalogue from "./accordion-analogue";
import Lottie from "lottie-react";
import SearchForm from "../main/search-form";
import spinner from '../../assets/spinner.json';
import {useLoadBrandsQuery} from "../../redux/rtk/api-rtk";

const Category = ({category, onItemClick, products}) => {

    const [isOpen, setIsOpen] = useState(false);
    const hasChildCategories = category.child_categories && category.child_categories.length > 0;

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = () => {
        if (!hasChildCategories) {
            onItemClick(category);
        }
    };

    return (
        <div className="category">
            <h2 className="category-title" onClick={hasChildCategories ? toggleAccordion : handleItemClick}>
                {category.name}
            </h2>
            {hasChildCategories && isOpen && (
                <ul className="subcategory-list">
                    {category.child_categories.map(childCategory => (
                        <li key={childCategory.key}>
                            <Category category={childCategory} onItemClick={onItemClick}/>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const ModelCatalog = ({url}) => {

    const dispatch = useDispatch();
    const location = useLocation();
    const {
        categories,
        chosenBrand,
        chosenModel,
        selectedEngine,
        isLoading,
    } = useSelector((store) => store.catalogSlice);
    const [activeKey, setActiveKey] = useState(null);
    const [products, setProducts] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const path = location.pathname.split('/');
    const model = path.pop();
    const brand = path.pop();

    useEffect(() => {
        const getCategories = async () => {
            dispatch(handleLoading({isLoading: true}));
            await fetch(`${url}/api/products/categories?brand=${brand}&model=${model}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    dispatch(categoriesStore(result.categories));
                    dispatch(handleLoading({isLoading: false}));
                    setActiveKey(result.categories[0].key);
                });
        };


        getCategories();
    }, [url, brand, model, dispatch]);

    const fetchProducts = async (key) => {
        dispatch(handleLoading({isLoading: true}));
        await fetch(`${url}/api/products?brand=${brand}&model=${model}&category=${key}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((response) => response.json())
            .then((result) => {
                setProducts(result.products);
                dispatch(handleLoading({isLoading: false}));
            });
    };

    useEffect(() => {
        if (selectedEngine && selectedEngine !== "Тип двигателя") {
            const filtered = products.filter((product) =>
                product.cars.some((car) => car.engine === selectedEngine)
            );
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts(products);
        }
    }, [selectedEngine, products]);

    const handleItemClick = (category) => {
        fetchProducts(category.key);
    };

    return (
        <>
            <SearchForm/>
            <div className="ModelCatalog ">
                <aside className="aside-wrapper">
                    {categories &&
                        categories.map((rootCategory) => (
                            <Category
                                key={rootCategory.key}
                                category={rootCategory}
                                onItemClick={handleItemClick}
                                products={products}
                            />
                        ))}
                </aside>
                {isLoading ? (
                    <div className="lottie-wrapper">
                        <Lottie className="animation" animationData={spinner} loop={true}/>
                    </div>
                ) : (
                    <div className={'products'}>
                        {filteredProducts !== null && filteredProducts.length > 0 ? (
                            filteredProducts.map((el, index) => (
                                <div key={el.id} className="product">
                                    <AccordionAnalogue analogue={el} i={el.key}/>
                                </div>
                            ))
                        ) : (
                            <p>{filteredProducts !== null && 'Здесь ничего пока нет!'}</p>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ModelCatalog;


// const SubCategory = ({subCategory, brand, model, url}) => {
//     const [isExpanded, setIsExpanded] = useState(false);
//     const [products, setProducts] = useState([]);
//     const {chosenBrand, chosenModel} = useSelector(store => store.catalogSlice);
//     console.log(products)
//     const fetchProducts = async (key) => {
//         // dispatch(handleLoading({isLoading: true}));
//         await fetch(`${url}/api/products?brand=${brand}&model=${model}&category=${key}`, {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Accept': 'application/json'
//             }
//         })
//             .then(response => response.json())
//             .then(result => {
//                 setProducts(result.products);
//                 // dispatch(handleLoading({isLoading: false}));
//             })
//     }
//
//
//     useEffect(() => {
//         if (isExpanded && subCategory.products_count !== "0") {
//             //  fetchProducts()
//         }
//     }, [isExpanded]);
//
//     return (
//         <div className="sub-category">
//             <h3 onClick={() => {
//                 setIsExpanded(!isExpanded)
//                 if (subCategory.products_count !== '0') {
//                     fetchProducts(subCategory.key)
//                 }
//             }}>{subCategory.name}</h3>
//             {isExpanded && subCategory.child_categories && subCategory.child_categories.map((childSubCategory, index) => {
//
//                 //  console.log(childSubCategory)
//                 return (
//                     childSubCategory.products_count === '0' ?
//                         <div key={index}>
//                             {childSubCategory.child_categories.map((child, index) => {
//                                 // console.log(child)
//                                 return (
//                                     <div key={index}
//                                          onClick={() => {
//                                              if (child.products_count !== '0') {
//                                                  fetchProducts(child.key)
//                                              }
//                                          }}
//                                     >
//                                         {child.name}
//
//
//                                     </div>
//                                 )
//                             })
//                             }
//                         </div>
//                         : <div
//                             onClick={() => {
//                                 console.log(childSubCategory)
//                             }}
//                         >{childSubCategory.name}</div>
//
//                     //  <AccordionAnalogue analogue={childSubCategory} i={childSubCategory.key}/>
//
//                 )
//             })}
//             {isExpanded && products.map((product) => (
//                 <div key={product.id} className="product">
//                     <AccordionAnalogue analogue={product} i={product.key}/>
//                 </div>
//             ))}
//         </div>
//     );
// };


