import React, { useState } from "react";
import { Link } from "react-router-dom";
import { StockTable } from "./stock-table/stock-table";
import { addItem } from "../../redux/slices/cartSlice";
import { useDispatch } from "react-redux";

const AccordionAnalogue = ({ analogue, i }) => {
    const dispatch = useDispatch();
    const [openStock, setOpenStock] = useState(false);

    const toggleStock = () => {
        setOpenStock(!openStock);
    };

    const arrowIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className={`arrow ${openStock ? "up" : "down"}`}
            viewBox="0 0 16 16"
        >
            <path d="M3.646 5.646a.5.5 0 0 1 .708 0L8 9.293l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708z" />
        </svg>
    );

    const sumProducts = analogue.storages?.reduce(
        (sum, current) => sum + Number(current.quantity),
        0
    );

    const addToCart = () => {
        dispatch(
            addItem({
                ...analogue,
                quantity: 1, // добавляем товар с количеством 1
            })
        );
        alert("Товар добавлен в корзину");
    };

    return (
        <div className="analogue__block">
            <Link to={"/catalog/product/" + analogue.key} className="analogue__wrapper">
                <div className="photo__wrapper">
                    <img src="/images/default-icon-product.svg" alt="analogue-pic" />
                </div>
                <div>
                    <h2>{analogue.name}</h2>
                    <p>Артикул группы аналогов: {analogue?.group_of_analogs?.analogs_group_article}</p>
                    <p>Артикул: {analogue.article}</p>
                    <p>Производитель: {analogue.manufacturer}</p>
                    <p className="price">{analogue.price} руб.</p>
                </div>
            </Link>
            <div className="analogue__block_cart">
                <p className="analogue__block-sum">Остатки по сети: {sumProducts} шт.</p>
                <button className="add__cart" onClick={addToCart}>
                    В корзину
                </button>
            </div>
            <div className="accordeon">
                <h2 onClick={toggleStock} className="accordeon-title">
                    {openStock ? "Скрыть" : "Показать наличие в магазинах"}
                    {arrowIcon}
                </h2>
                {openStock && analogue.storages && <StockTable storages={analogue.storages} />}
            </div>
        </div>
    );
};

export default AccordionAnalogue;
