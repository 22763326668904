import React from "react";
import { Link, Navigate } from "react-router-dom";

import Header from "../header";
import Footer from "../footer";

const TermsService = ({ headerNav, footerNav, isLogged, authUser, handleLogOut }) => {
    if (!isLogged) {
        return <Navigate to='/' replace />;
    }

    return (
        <>
            <Header headerNav={headerNav} isLogged={isLogged} authUser={authUser} handleLogOut={handleLogOut} />
            <section className='personal_area'>
                <nav>
                    <ul>
                        <Link to={`/personal-area/info`} className='private'><li>Личная информация</li></Link>
                        <Link to={`/personal-area/orders`} className='orders'><li>Заказы</li></Link>
                        <Link to={`/personal-area/terms`} className='agreement active'><li>Условия обслуживания</li></Link>
                    </ul>
                </nav>

                </section>
            <Footer footerNav={footerNav} />
        </>
    );
};

export default TermsService;
