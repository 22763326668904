import React, {useState} from "react";

const AccordionAnalogueCart = ({selected, analogue, products, current, limit, navigate}) => {
    const [openStock, setOpenStock] = useState(null);

    const navigateToProductCart = (key) => {
        navigate(`/catalog/product/${key}`);
    };

    const filteredProducts = analogue.filter((item) => item !== null);


    return (
        filteredProducts && filteredProducts.map((item) => (
            <div className="analogue__block" key={item.id}>
                <div className="analogue__wrapper" onClick={() => navigateToProductCart(item.key)}>
                    <div className="photo__wrapper">
                        <img src="/images/default-icon-product.svg" alt="analogue-pic"/>
                    </div>
                    <div>
                        <h2 className="product__title">{item.name}</h2>
                        <p>Артикул: {item.article}</p>
                        <p>Производитель: {item.manufacturer}</p>
                        <p className="price">{item.price} руб.</p>
                    </div>

                </div>
                {item.article === openStock ? (
                    <button className={item.name === openStock ? 'active' : ''} onClick={() => setOpenStock(null)}>
                        Скрыть остатки по складам
                    </button>
                ) : (
                    <button className={item.name === openStock ? 'active' : ''}
                            onClick={() => setOpenStock(item.article)}>
                        Показать остатки по складам
                    </button>
                )}
                <div className={`in-stock ${item.article === openStock ? 'open' : 'hidden'}`}>
                    <h2>В наличии:</h2>
                    <table className='stock-table'>
                        <thead>
                        <tr>
                            <th>Город</th>
                            <th>Улица</th>
                            <th>Количество</th>
                        </tr>
                        </thead>
                        <tbody>
                        {item.storages.map((store, i) => (
                            <tr key={i}>
                                <td>{store.city}</td>
                                <td>{store.address}</td>
                                <td>{store.pivot.quantity} шт.</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        ))
    );
};

export default AccordionAnalogueCart;
