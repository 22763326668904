import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import Wrapper from "./wrapper";
import AuthorizationContainer from "./components/authorization/authorization-container";
import RegistrationContainer from "./components/authorization/registration-container";
import PrivacyInfo from "./components/personal/privacy-info";
import Orders from "./components/personal/orders";
import TermsService from "./components/personal/terms-service";
import {useDispatch} from "react-redux";
import {handleLogInState} from "./redux/slices/authSlice";

export const url = 'https://avd-backend.veon-tech.ru';
export const headers = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

const App = (props) => {
  const dispatch = useDispatch()
  const [isLogged, changeLogged] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const [catalog, setCatalog] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [pagination, setPagination] = useState({ start: 1, end: 10 });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // При загрузке страницы извлекаем данные пользователя из localStorage
    const savedUser = localStorage.getItem('user');

    if (savedUser) {
      setAuthUser(JSON.parse(savedUser).data);
      changeLogged(true)
      dispatch(handleLogInState({ userInfo: JSON.parse(savedUser).data } ))
    }
  }, []);

  // const loadCarsBrandToCatalog = async () => {
  //   await fetch(`${url}/api/cars/brands`, {
  //     method: 'GET',
  //     headers
  //   })
  //     .then(response => response.json())
  //     .then(result => {
  //       setBrandList(result.brands);
  //       handlePagination(result.brands);
  //     })
  // }

  const loadCarsModelToCatalog = async (brand) => {
    let model;
    await fetch(url + '/api/cars/models?brand=' + brand, {
      method: 'GET',
      headers
    })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        model = result.models;
      })
      .catch(err => console.log(err))
    return model;
  }

  const handlePagination = (brands) => {
    const res = [];
    const paginationArray = brands.slice(pagination.start, pagination.end);
    paginationArray.forEach((brand, i) => {
      if (brand) {
        loadCarsModelToCatalog(brand)
          .then(result => {
            res.push({ name: brand, models: result });

            if (i === paginationArray.length - 1) {
              setCatalog([...catalog, ...res]);
            }
          })
      }
    })
  }

  const handleLogIn = (data) => {
    changeLogged(true);
    setAuthUser(data);
  }

  const handleLogOut = () => {
    changeLogged(false);
    setAuthUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
  }

  const handleNewPersonalInfo = (data) => {
    const addPersonalInfo = { ...authUser, ...data };
    const localUsers = JSON.parse(localStorage.getItem('users'));
    const changedLocalUsers = localUsers.map(user => {
      if (user.login === addPersonalInfo.login) {
        return addPersonalInfo;
      } else {
        return user;
      }
    })

  //  localStorage.setItem('users', JSON.stringify(changedLocalUsers));

    setAuthUser(addPersonalInfo);
  }

  return (
    <div className='wrapper'>
      <Routes>
        <Route path='*' element={<Wrapper
          {...props}
          location={location}
          isLogged={isLogged}
          authUser={authUser}
          catalog={catalog}
          url={url}
          brandList={brandList}
          pagination={pagination}
          setPagination={setPagination}
          handlePagination={handlePagination}
          handleLogOut={handleLogOut}
          navigate={navigate}
        />}
        />
        <Route path='/login/*' element={<AuthorizationContainer
          handleLogIn={handleLogIn}
          isLogged={isLogged}
          authUser={authUser} />}
        />
        <Route path='/registration/*' element={<RegistrationContainer
          navigate={navigate}
          handleLogIn={handleLogIn} />}
        />
        {isLogged &&
          <>
            <Route path='/personal-area/info/*' element={<PrivacyInfo
              {...props}
              isLogged={isLogged}
              authUser={authUser}
              handleLogOut={handleLogOut}
              handleNewPersonalInfo={handleNewPersonalInfo} />}
            />

            <Route path='/personal-area/orders/*' element={<Orders
              {...props}
              isLogged={isLogged}
              authUser={authUser}
              handleLogOut={handleLogOut} />}
            />
            <Route path='/personal-area/terms/*' element={<TermsService
              {...props}
              isLogged={isLogged}
              authUser={authUser}
              handleLogOut={handleLogOut} />}
            />
          </>
        }
      </Routes>
    </div>
  )
}

export default App;
