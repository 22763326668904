import {useState} from "react";
import {useForm} from "react-hook-form";
import {useLoginMutation} from "../../redux/rtk/api-rtk";

const AuthorizationForm = ({handleLogIn, navigate}) => {
    const [statePassword, changeStatePassword] = useState(false);
    const [errorAuth, setError] = useState(null); // Переменная для хранения ошибок авторизации

    const {
        register,
        formState: {errors},
        handleSubmit,
        reset
    } = useForm({mode: 'onBlur'});

    const [login, {isLoading}] = useLoginMutation();

    const onSubmit = async (data) => {
        try {
            const result = await login(data).unwrap(); // Выполняем запрос на авторизацию
            // Предполагаем, что сервер возвращает объект пользователя в случае успешного входа

            // Сохраняем данные пользователя в localStorage
            localStorage.setItem('user', JSON.stringify(result));
            localStorage.setItem('token', JSON.stringify(result.token));

            handleLogIn(result.data); // Обрабатываем успешную авторизацию
            reset();
            //   navigate('/'); // Перенаправление на страницу после успешной авторизации
        } catch (err) {
            // Обработка ошибок
            if (err?.data?.message) {
                setError(err.data.message); // Устанавливаем сообщение об ошибке из ответа сервера
            } else {
                setError('Произошла ошибка при авторизации.'); // Общее сообщение об ошибке
            }
        }
    };

    return (
        <form className='authorization-form' onSubmit={handleSubmit(onSubmit)}>
            <div className='error-message'>{errorAuth && <p>{errorAuth}</p>}</div>

            <label>
                Электронная почта
                <input
                    type='email'
                    placeholder='Введите электронную почту'
                    className={errors.email ? 'error' : ''}
                    {...register('email', {
                        required: 'Это поле обязательно к заполнению.',
                        pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/,
                            message: 'E-mail введён некорректно.'
                        }
                    })}
                />
            </label>
            <div className='error-message'>
                {errors.email && <p>{errors.email.message || 'Ошибка!'}</p>}
            </div>

            <label className='password'>
                Пароль
                <input
                    type={statePassword ? 'text' : 'password'}
                    className={`${statePassword ? 'active' : ''} ${errors.password ? 'error' : ''}`}
                    placeholder='Введите пароль'
                    {...register('password', {
                        required: 'Это поле обязательно к заполнению.',
                        minLength: {
                            value: 6,
                            message: 'Пароль должен содержать не менее 6 символов.'
                        },
                        maxLength: {
                            value: 16,
                            message: 'Пароль не должен превышать 16 символов.'
                        }
                    })}
                />
                <p className={statePassword ? 'active' : ''} onClick={() => changeStatePassword(!statePassword)}></p>
            </label>
            <div className='error-message'>
                {errors.password && <p>{errors.password.message || 'Ошибка!'}</p>}
            </div>

            <div className='remember'>
                <label>
                    <input type='checkbox'/>
                    Запомнить меня
                </label>
                <button type='button'>Забыли пароль?</button>
            </div>

            <input type='submit' value='Войти'/>
            {isLoading && <p>Авторизация...</p>}
        </form>
    );
};

export default AuthorizationForm;
