import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import api from "../axios/api";

export const getBrands = createAsyncThunk(
    'getBrands',
    async () => {
        const response = await api.get('cars/brands');
        return response.data;
    },
);

export const getModels = createAsyncThunk(
    'getModels',
    async (brand) => {
        const response = await api.get(`cars/models?brand=${brand}`);
        return response.data;
    },
);
export const getEngines = createAsyncThunk(
    'getEngines',
    async ({selectedBrand, model}) => {
        const response = await api.get(`cars/engines?brand=${selectedBrand}&model=${model}`);
        return response.data;
    },
);


export const searchProducts = createAsyncThunk(
    'searchProducts',
    async ({ inputData, brand, model, engine  }) => {
        const response = await api.get(`products/search?search=${inputData}&brand=${brand}&model=${model}&engine=${engine}`);
        return response.data;
    },
);

const catalogSlice = createSlice({
    name: 'catalogSlice',
    initialState: {
        brands: [],
        models: [],
        engines: [],
        searchProducts: [],
        selectedBrand: '',
        selectedModel: '',
        selectedEngine: '',
        searchValue: '',
        isLoading: false,
        categories: [],
        products: [],
    },
    reducers: {
        setSelectedBrand: (state, action) => {
            state.selectedBrand = action.payload;
        },
        setSelectedModel: (state, action) => {
            state.selectedModel = action.payload;
        },
        setSelectedEngine: (state, action) => {
            state.selectedEngine = action.payload;
        },
        setSearchValue: (state, action) => {
            state.searchValue = action.payload;
        },
        //////// проверить код ниже
        getBrandList(state, action) {
            const { brandList } = action.payload;
            state.brandList = brandList;
        },
        getChosenBrand(state, action) {
            const { chosenBrand } = action.payload;
            state.chosenBrand = chosenBrand;
        },
        getChosenModel(state, action) {
            const { chosenModel } = action.payload;
            state.chosenModel = chosenModel;
        },
        handleLoading(state, action) {
            const { isLoading } = action.payload;
            state.isLoading = isLoading;
        },
        categoriesStore(state, action) {
            state.categories = [...action.payload];
        },
        productsStore(state, action) {
            state.products = [...action.payload];
        }
        //////////////////
    },
    extraReducers: (builder) => {
        builder.addCase(getBrands.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getBrands.fulfilled, (state, action) => {
            state.brands = action.payload.brands;
            state.isLoading = false;
        });
        builder.addCase(getBrands.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(getEngines.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getEngines.fulfilled, (state, action) => {
            state.engines = action.payload.engines;
            state.isLoading = false;
        });
        builder.addCase(getEngines.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(getModels.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getModels.fulfilled, (state, action) => {
            state.models = action.payload.models;
            state.isLoading = false;
        });
        builder.addCase(getModels.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(searchProducts.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(searchProducts.fulfilled, (state, action) => {
            state.searchProducts = action.payload.products;
            state.isLoading = false;
        });
        builder.addCase(searchProducts.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const {
    getBrandList,
    getChosenBrand,
    getChosenModel,
    handleLoading,
    categoriesStore,
    productsStore ,
    setSelectedBrand,
    setSelectedEngine,
    setSelectedModel,
    setSearchValue
} = catalogSlice.actions;
export default catalogSlice.reducer;

