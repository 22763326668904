import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [],
    total: 0,
};

const updateTotal = (items) => items.reduce((total, item) => total + item.price * item.quantity, 0);

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addItem(state, action) {
            const existingItem = state.items.find(item => item.id === action.payload.id);
            if (existingItem) {
                // Увеличиваем количество на переданное значение
                existingItem.quantity += action.payload.quantity;
            } else {
                // Добавляем новый товар с указанным количеством
                state.items.push({ ...action.payload, quantity: action.payload.quantity });
            }
            state.total = updateTotal(state.items);
            localStorage.setItem('cartItems', JSON.stringify(state.items)); // Синхронизируем с localStorage
        },
        updateCartItem(state, action) {
            const { id, quantity } = action.payload;
            const existingItem = state.items.find(item => item.id === id);
            if (existingItem) {
                existingItem.quantity = quantity;
            }
            state.total = updateTotal(state.items);
            localStorage.setItem('cartItems', JSON.stringify(state.items)); // Синхронизируем localStorage
        },
        removeItem(state, action) {
            const existingItem = state.items.find(item => item.id === action.payload);
            if (existingItem) {
                existingItem.quantity -= 1;
                if (existingItem.quantity === 0) {
                    state.items = state.items.filter(item => item.id !== action.payload);
                }
            }
            state.total = state.items.reduce((total, item) => total + item.price * item.quantity, 0);
            localStorage.setItem('cartItems', JSON.stringify(state.items));
        },
        removeCartItem(state, action) {
            state.items = state.items.filter(item => item.id !== action.payload);
            state.total = updateTotal(state.items);
            localStorage.setItem('cartItems', JSON.stringify(state.items)); // Синхронизируем localStorage
        },
        loadCartFromStorage(state) {
            const storedItems = JSON.parse(localStorage.getItem('cartItems')) || [];
            state.items = storedItems;
            state.total = storedItems.reduce((total, item) => total + item.price * item.quantity, 0);
        },
    },
});

export const { addItem,removeCartItem, updateCartItem, removeItem, loadCartFromStorage } = cartSlice.actions;

export default cartSlice.reducer;
