import React from "react";
import {Link} from 'react-router-dom';
import SearchForm from '../main/search-form';
import {useDispatch, useSelector} from "react-redux";
import {getChosenModel, getEngines, setSelectedModel} from "../../redux/slices/catalogSlice";
import {useLoadBrandsQuery} from "../../redux/rtk/api-rtk";

const BrandCatalog = () => {

    const dispatch = useDispatch();
    const {chosenBrand, selectedBrand} = useSelector(store => store.catalogSlice);

    const onclickChosenModelHandler = (model) => {
        dispatch(getChosenModel({chosenModel: model}))
        dispatch(setSelectedModel(model))
        dispatch(getEngines({selectedBrand, model}))
    }

    return (
        <main>
            <SearchForm/>
            <section className='brand-catalog'>
                {chosenBrand.models.map((model, i) => {
                    return <Link
                        to={`/catalog/${chosenBrand.brand}/${model}`}
                        onClick={() => onclickChosenModelHandler(model)}
                        key={i}
                    >{model}</Link>
                })}
            </section>
        </main>
    )
}

export default BrandCatalog;
