import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    getBrands, getEngines,
    getModels,
    searchProducts, setSearchValue,
    setSelectedBrand, setSelectedEngine,
    setSelectedModel
} from "../../redux/slices/catalogSlice";
import {useDispatch, useSelector} from "react-redux";


const SearchForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
  //  const [searchInput, setSearchInput] = useState('');
    const {
        brands,
        selectedBrand,
        models,
        selectedModel,
        engines,
        selectedEngine,
        searchValue,
        isLoading
    } = useSelector(state => state.catalogSlice)

    const handleBrandChange = (brand) => {
        dispatch(getEngines(''))

        dispatch(setSelectedBrand(brand))
        dispatch(getModels(brand))
    };

    const handleModelChange = (model) => {
        dispatch(setSelectedModel(model))
        dispatch(getEngines({selectedBrand, model}))
    };

    const handleEngineChange = (engine) => {
        dispatch(setSelectedEngine(engine))
    };

    const handleSearch = () => {
        // Вызовите thunk для выполнения поиска
        dispatch(
            searchProducts({
                inputData: searchValue,
                brand: selectedBrand,
                model: selectedModel,
                engine: selectedEngine, // Добавьте engine, если необходимо
                // Передайте данные поиска
            })
        );
        navigate(`/catalog/search/${searchValue}`);
    };

    useEffect(() => {
        dispatch(getBrands())
    }, [])

    return (
        <div className='search-form'>
            <div className='search-form__params'>
                <h2>Поиск автозапчастей китайских автомобилей</h2>
                <div>
                    <input
                        type='text'
                        value={searchValue}
                        onChange={(e) => dispatch(setSearchValue(e.target.value))}
                        placeholder='Введите данные для поиска'
                    />
                    <button onClick={handleSearch} disabled={isLoading}>
                        {isLoading ? 'Идет поиск...' : 'Поиск'}
                    </button>
                </div>
                <div>
                    <div className='select-wrapper'>
                        <select
                            onChange={(e) => handleBrandChange(e.target.value)}
                            value={selectedBrand}
                        >
                            <option>Выберите марку</option>
                            {brands && brands.map((brand) => <option key={brand}>{brand}</option>)}
                        </select>
                    </div>
                    <div className='select-wrapper'>
                        <select
                            disabled={models.length === 0}
                            onChange={(e) => handleModelChange(e.target.value)}
                            value={selectedModel}
                        >
                            <option>Выберите модель</option>
                            {models && models.map((model) => <option key={model}>{model}</option>)}
                        </select>
                    </div>
                    <div className='select-wrapper'>
                        <select
                            disabled={engines.length === 0}
                            onChange={(e) => handleEngineChange(e.target.value)}
                            value={selectedEngine}>
                            <option>{engines.length === 0 ? 'Нет двигателей' : 'Тип двигателя'} </option>
                            {engines.map((engine) => (
                                <option key={engine}>{engine}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='search-form__back-call'>
                <a href='tel:+7 8442 600 389'>+7 8442 600 389</a>
                <p>Заказ обратного звонка</p>
            </div>
        </div>
    )
}

export default SearchForm;
