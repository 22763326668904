import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const apiRtk = createApi({
    reducerPath: 'apiRtk',
    baseQuery: fetchBaseQuery({baseUrl: 'https://avd-backend.veon-tech.ru'}),
    endpoints: (builder) => ({
        loadBrands: builder.query({
            query: () => '/api/cars/cars',
        }),
        getOrders: builder.query({
            query: () => {
                // Получение токена из localStorage
                const token = localStorage.getItem('token');

                return {
                    url: '/api/orders',
                    headers: {
                        // Добавление токена в заголовок Authorization
                        Authorization: token ? `Bearer ${JSON.parse(token)}` : '',
                    },
                };
            },
        }),
        getOrder: builder.query({
            query: (products) => {
                const params = {};

                if (products) {
                    params.products = products;
                }

                return {
                    url: '/api/orders/create',
                    method: 'GET',
                    params,
                };
            },
        }),
        createOrder: builder.mutation({
            query: (orderData) => {
                // Получение токена из localStorage
                const token = localStorage.getItem('token');

                return {
                    url: '/api/orders',
                    method: 'POST',
                    body: orderData,
                    headers: {
                        // Добавление токена в заголовок Authorization
                        Authorization: token ? `Bearer ${JSON.parse(token)}` : '',
                    },
                };
            },
        }),
        signupUser: builder.mutation({
            query: (userData) => ({
                url: '/api/signup',
                method: 'POST',
                body: userData,
            }),
        }),
        login: builder.mutation({
            query: (loginData) => ({
                url: '/api/login',
                method: 'POST',
                body: loginData,
            }),
        }),
    }),
});

export const {
    useLoadBrandsQuery,
    useSignupUserMutation,
    useGetOrderQuery,
    useCreateOrderMutation,
    useLoginMutation,
    useGetOrdersQuery,
} = apiRtk;
