import React from 'react';
import './StockTable.scss'

export const StockTable = ({storages}) => {
    return (
        <table className='stock-table'>
            <thead>
            <tr>
                <th>Город</th>
                <th>Улица</th>
                <th>Количество</th>
            </tr>
            </thead>
            <tbody>
            {storages.map((store, i) => (
                <tr key={i}>
                    <td>{store.city}</td>
                    <td>{store.address}</td>
                    <td>{store.quantity} шт.</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}
