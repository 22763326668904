import { configureStore } from '@reduxjs/toolkit';
import authAdminReducer from './slices/authSlice';
import catalogSliceReducer from './slices/catalogSlice';
import { apiRtk } from "./rtk/api-rtk";
import cartSlice from "./slices/cartSlice";

const reduxStore = configureStore({
    reducer: {
        authAdmin: authAdminReducer,
        cart: cartSlice,
        catalogSlice: catalogSliceReducer,
        [apiRtk.reducerPath]: apiRtk.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(apiRtk.middleware),
});

export default reduxStore;
