import React, {useState} from "react";
import {Link, Navigate} from "react-router-dom";

import Header from "../header";
import Footer from "../footer";
import {useGetOrderQuery, useGetOrdersQuery} from "../../redux/rtk/api-rtk";

function formatOrderDate(dateString) {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthNames = [
        "января", "февраля", "марта", "апреля", "мая", "июня",
        "июля", "августа", "сентября", "октября", "ноября", "декабря"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `Принят ${day} ${month} ${year}`;
}

const Orders = ({headerNav, footerNav, isLogged, authUser, handleLogOut}) => {
    const {data: orders, isLoading} = useGetOrdersQuery()

    const [expandedOrder, setExpandedOrder] = useState(null);

    if (!isLogged) {
        return <Navigate to='/' replace/>;
    }


    const toggleOrderDetails = (orderId) => {
        if (expandedOrder === orderId) {
            setExpandedOrder(null);
        } else {
            setExpandedOrder(orderId);
        }
    };


    return (
        <>
            <Header headerNav={headerNav} isLogged={isLogged} authUser={authUser} handleLogOut={handleLogOut}/>
            <section className='personal_area'>
                <nav>
                    <ul>
                        <Link to={`/personal-area/info`} className='private'>
                            <li>Личная информация</li>
                        </Link>
                        <Link to={`/personal-area/orders`} className='orders active'>
                            <li>Заказы</li>
                        </Link>
                        <Link to={`/personal-area/terms`} className='agreement'>
                            <li>Условия обслуживания</li>
                        </Link>
                    </ul>
                </nav>
                {
                    isLoading ? <div>Загрузка заказов....</div>
                        : <div className="orders-list">
                            {orders?.data.map(order => {
                                // Парсинг items из строки в массив объектов
                                const parsedItems = JSON.parse(order.items);

                                return (
                                    <div key={order.id} className="order-item">
                                        <div className="order-header">
                                            <div>
                                                <p className="order-number">
                                                    <button
                                                        className="toggle-button"
                                                        onClick={() => toggleOrderDetails(order.id)}
                                                    >
                                                        {expandedOrder === order.id ? '▼' : '▶'}
                                                    </button>

                                                    Заказ № {order.id}
                                                </p>
                                                <p>{formatOrderDate(order.created_at)}</p>
                                            </div>

                                            <p
                                                className={`order-status ${order.status === 'завершён' ? 'completed' : 'not-completed'}`}>
                                                {order.status === 'завершён' ? 'Завершён' : 'Не завершён'}
                                            </p>
                                        </div>
                                        {expandedOrder === order.id && (
                                            <div className="order-details">
                                                <p><strong>Адрес:</strong> {order.address}</p>
                                                <p><strong>Телефон:</strong> {order.phone}</p>
                                                <p><strong>Способ оплаты:</strong> {order.payment_method}</p>
                                                <p><strong>Способ доставки:</strong> {order.shipping_method}</p>
                                                <p><strong>Комментарий:</strong> {order.comment}</p>
                                                {/*<p><strong>Дата создания:</strong> {order.created_at}</p>*/}
                                                <div className="items-list">
                                                    {parsedItems.map((item, index) => (
                                                        <div key={index} className="order-item-details">
                                                            <p>{item.name}</p>
                                                            <p>Артикул: {item.article}</p>
                                                            <p>Цена: {item.price} руб.</p>
                                                            <p>Количество: {item.quantity} шт.</p>
                                                        </div>
                                                    ))}
                                                </div>
                                                <p className="order-total">Стоимость заказа: {order.total} руб.</p>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                }

            </section>

            <Footer footerNav={footerNav}/>
        </>
    );
};

export default Orders;
