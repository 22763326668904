import React from "react";

const TermsOfUse = () => {
    return (
        <section className='terms-of-use__wrapper'>
            <div className='title'>
                <p>Условия обслуживания</p>
                <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
            </div>
            <div className='terms'>
                <p>
                    1. Основные понятия.<br />
                    1.1. Интернет-магазин — интернет-сайт, расположенный по адресу: (ссылка) на котором представлены Товары, предлагаемые Продавцом для приобретения и все связанные с ним поддомены;<br />
                    1.2. Посетитель Интернет-магазина — лицо, пришедшее на сайт Интернет-магазина без цели размещения Заказа;<br />
                    1.3. Пользователь — физическое либо юридическое лицо, посетитель Интернет-магазина, принимающий условия настоящего Соглашения и желающий разместить Заказы в Интернет-магазине;<br />
                    1.4. Покупатель — Пользователь, оформивший Заказ в Интернет-магазине;<br />
                    1.5. Продавец — (реквизиты организации, адрес).;<br />
                    1.6. Товар — вещи, представленные к продаже в Интернет-магазине;<br />
                    1.7. Заказ — должным образом, оформленный запрос Покупателя на приобретение и доставку по указанному Покупателем адресу / посредством самовывоза Товаров, выбранных в Интернет-магазине.<br />
                </p>
                <p className='general-provisions'>
                    2. Общие положения.<br />
                    2.1. Продавец осуществляет продажу Товаров через Интернет-магазин.<br />
                    2.2. Заказывая Товары через Интернет-магазин, Пользователь соглашается с условиями, изложенными ниже. В случае несогласия с условиями, изложенными в настоящем Пользовательском соглашении (далее — Соглашение или Публичная оферта) Пользователь обязан немедленно прекратить использование сервиса и покинуть Интернет-магазин.<br />
                    2.3. Настоящее Пользовательское соглашение, а также информация о Товаре, являются публичной офертой в соответствии со статьей 435 и пунктом 2 статьи 437 Гражданского кодекса Российской Федерации.<br />
                    2.4. Соглашение может быть изменено Продавцом в одностороннем порядке без уведомления Пользователя/Покупателя. Новая редакция Соглашения вступает в силу по истечении 10 (Десяти) календарных дней с момента ее опубликования.<br />
                    2.5. Публичная оферта признается акцептованной Посетителем Интернет-магазина / Покупателем с момента регистрации Посетителя в Интернет-магазине, оформления Покупателем Заказа без авторизации, момента принятия от Покупателя Заказа по телефону (телефон).<br />
                    2.6. Осуществляя Заказ, Пользователь/Покупатель соглашается с тем, что Продавец может поручить исполнение Договора третьему лицу, при этом оставаясь ответственным за его исполнение.<br />
                    2.7. Все права и обязательства по заключенному с Пользователем Договору возникают непосредственно у Продавца.<br />
                </p>
                <p>
                    3. Предмет соглашения.<br />
                    3.1. Предметом настоящего Соглашения является предоставление возможности Пользователям приобретать Товары, представленные в Интернет-магазине.<br />
                    3.2. Данное Соглашение распространяется на все виды Товаров и услуг, представленных в Интернет-магазине, пока такие предложения с описанием присутствуют в каталоге Интернет-магазина.<br />
                </p>
            </div>
            <div className='buttons'>
                <button className='load-more'>Загрузить ещё</button>
                <button className='apply-agreement'>Принять соглашение</button>
            </div>
        </section>
    );
};

export default TermsOfUse;
