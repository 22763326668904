import {useSelector} from "react-redux";
import Lottie from "lottie-react";
import spinner from "../../../assets/spinner.json";
import SearchForm from "../../main/search-form";
import {Link} from "react-router-dom";

export const SearchResults = ({url, location}) => {
    const {searchProducts, chosenBrand, chosenModel, isLoading} = useSelector(
        (store) => store.catalogSlice
    );

    return (
        <section className="search-results">
            <SearchForm catalog={[]}/>
            {isLoading ? (
                <Lottie className="animation" animationData={spinner} loop={true}/>
            ) : (
                searchProducts && (
                    <div className="search-results__list">
                        {searchProducts.length === 0 ? (
                            <p>Ничего не найдено по вашему запросу.</p>
                        ) : (
                            searchProducts.map((item) => (
                                <Link
                                    className="search-results__item"
                                    to={"/catalog/product/" + item.key}
                                    key={item.key}
                                >
                                    <img src="/images/default-icon-product.svg" alt="product-pic"/>
                                    <h2>{item.name}</h2>
                                    <p>Производитель: {item.manufacturer}</p>
                                    <p>Страна: {item.country}</p>
                                    <h3>Цена: {item.price} руб.</h3>
                                </Link>
                            ))
                        )}
                    </div>
                )
            )}
        </section>
    );
};
